export function setPictureList(This, list) { // 设置图片列表
  if (This.userid === `111.9.53.181:8000` || This.userid === '111.9.53.181:8001') { // 龙头石
    for(let i = 0; i < list.length; i++) {
      list[i] = `http://111.9.53.181:8002${list[i]}`
    }
  }
  if (This.userid === '192.168.0.68:8001') { // 蒙毅龙头石
    for(let i = 0; i < list.length; i++) {
      list[i] = `http://192.168.0.68:8000${list[i]}`
    }
  }

  if (This.userid === `10.30.201.240:83` || // 中治采集云
  This.userid === '111.9.53.181:83' ||
  This.userid === '10.30.201.240:80' || // 中治业务云
  This.userid === '10.30.201.240:8000') {
    for(let i = 0; i < list.length; i++) {
      list[i] = `http://10.30.201.240:8000${list[i]}`
    }
  }
  return list
}