<template>
  <div id="update">
    <mu-container>
      <mu-form
        :model="form_c"
        class="mu-demo-form"
        label-position="left"
        label-width="170"
      >
        <mu-form-item prop="input" label="应用名称" :rules="inputRules">
          <mu-text-field v-model="form_c.name"></mu-text-field>
        </mu-form-item>

        <mu-form-item prop="switch" label="是否不使用查看权限密码">
          <mu-switch v-model="form_c.public"></mu-switch>
        </mu-form-item>

        <mu-form-item label="查看权限密码" prop="password" :rules="inputRules">
          <mu-text-field
            :disabled="form_c.public"
            type="password"
            v-model="form_c.password"
            prop="password"
          ></mu-text-field>
        </mu-form-item>

        <mu-form-item prop="switch" label="是否不使用控制权限密码">
          <mu-switch v-model="form_c.usecontrolpwd"></mu-switch>
        </mu-form-item>

        <mu-form-item label="控制权限密码" prop="password" :rules="inputRules">
          <mu-text-field
            :disabled="form_c.usecontrolpwd"
            type="password"
            v-model="form_c.controlpassword"
            prop="password"
          ></mu-text-field>
        </mu-form-item>
      </mu-form>
    </mu-container>

    <mu-button
      color="primary"
      style="float: right; margin-right: 30px; margin-top: 10px"
      @click="define"
      >修改</mu-button
    >
    <mu-button
      color="Red"
      style="float: right; margin-right: 30px; margin-top: 10px"
      @click="close"
      >取消</mu-button
    >
  </div>
</template>

<script>
import { dynalt_Update } from "../network/dynalt.js";

let lodash = require("lodash");

export default {
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    layerid: {
      type: String,
      default: "",
    },
    success: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form_c: lodash.cloneDeep(this.form),
      inputRules: [{ validate: (val) => !!val, message: "不能为空" }],
    };
  },
  mounted() {
    console.log(this.form_c)
  },
  methods: {
    close() {
      this.$layer.close(this.layerid);
    },
    define() {
      if (this.form_c.name == "") {
        this.$toast.error("组态名称为空");
        return;
      }

      if (this.form_c.public == false && this.form_c.password == "") {
        this.$toast.error("密码为空");
        return;
      }

      dynalt_Update(this.form_c)
        .then((res) => {
          this.$toast.success(res.data.msg);
          this.$layer.close(this.layerid);
          this.success();
        })
        .catch((err) => {
          this.$toast.error("修改失败");
        });
    },
  },
};
</script>

<style lang="stylus" >
#update {
  width: 100%;
  height: 100%;

  padding: 20px;
}
</style>
